import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found section">
      <div className="container">
        <h1>NOT FOUND</h1>
        <p>Pagina inesistente</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
